import * as yup from 'yup';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';									   
import keys from 'lodash/keys';
import map from 'lodash/map';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import { timezoneNames } from './validation/timezoneNames';


import states from './validation/states';
import postalCodes from './validation/postalCodes';
import i18next from './language';
import { MGDL_UNITS, MMOLL_UNITS } from '../core/constants';

const t = i18next.t.bind(i18next);

yup.setLocale({
  mixed: {
    notType: ({ type }) => {
      let msg = t('Please enter a valid {{type}}',{type: type});

      if (type === 'date') {
        msg += t(' in the requested format');
      }

      return msg;
    },
  },
});

export const dateFormat = 'YYYY-MM-DD';
export const dateRegex = /^(.*)[-|/](.*)[-|/](.*)$/;

export const roles = [
  { value: 'clinic_manager', label: t('Clinic Manager') },
  { value: 'dietetist', label: t('Dietetist') },
  { value: 'endocrinologist', label: t('Endocrinologist') },
  { value: 'pediatrist', label: t('Pediatrist') },
  { value: 'podiatrist', label: t('Podiatrist') },
  { value: 'ophthalmologist', label: t('Ophthalmologist') },
  { value: 'nurse', label: t('Nurse/Nurse Practitioner') },
  { value: 'dentist', label: t('Dentist') },
  { value: 'biologist', label: t('Biologist') },
  { value: 'pharmacy', label: t('Pharmacist') },
  { value: 'cardiologist', label: t('Cardiologist') },
  { value: 'other', label: t('Other') },
];

export const clinicTypes = [
  { value: 'provider_practice', label: t('Provider Practice') },
  { value: 'healthcare_system', label: t('Healthcare System') },
  { value: 'clinic', label: t('Clinic') },
  { value: 'liberal', label: t('Liberal') },
  { value: 'hospital', label: t('Hospital') },  
  { value: 'dispensary', label: t('Dispensary') },
  { value: 'medical_center', label: t('Medical Center') },
  { value: 'other', label: t('Other') },
];

export const clinicSizes = [
  { value: '0-249', label: t('0-249') },
  { value: '250-499', label: t('250-499') },
  { value: '500-999', label: t('500-999') },
  { value: '1000+', label: t('1000+') },
];

export const preferredBgUnits = [
  { value: MGDL_UNITS, label: MGDL_UNITS },
  { value: MMOLL_UNITS, label: MMOLL_UNITS },
];

export const lastUploadDateFilterOptions = [
  { value: 1, label: t('Today') },
  { value: 2, label: t('Last 2 days') },
  { value: 7, label: t('Last 7 days') },
  { value: 14, label: t('Last 14 days') },
  { value: 30, label: t('Last 30 days') },
];

export const summaryPeriodOptions = [
  { value: '1d', label: t('24 hours') },
  { value: '7d', label: t('7 days') },
  { value: '14d', label: t('14 days') },
  { value: '30d', label: t('30 days') },
];

export const timezoneOptions = map(
  timezoneNames,
  name => ({ value: name, label: name })
);

export const maxClinicPatientTags = 50;


export const clinicPlansNames = {
  base: t('Base'),
  activeSalesBase: t('Base'),
  honoredBase: t('Base'),
  internationalBase: t('Base'),
  essential: t('Essential'),
  professional: t('Professional'),
  enterprise: t('Enterprise'),
}

export const clinicTierDetails = (clinic = {}) => {
  const {
    tier = DEFAULT_CLINIC_TIER,
    country,
    patientCountSettings = {},
  } = clinic;

  const hardLimitStartDate = patientCountSettings?.hardLimit?.startDate;
  const hardLimitStartDateIsFuture = hardLimitStartDate && moment(hardLimitStartDate).isValid() && moment(hardLimitStartDate).isAfter();
  const isBaseTier = tier.indexOf('tier01') === 0;
  let activeTier = tier;

  // Handle various base tier clinic states
  if (isBaseTier) {
    const isOUS = country !== 'US';
    const isInActiveSalesConversation = !isOUS && !hardLimitStartDate;
    const isHonoredBaseClinic = !isOUS && hardLimitStartDateIsFuture;

    if (isOUS) {
      // Ensure OUS clinics render as international plan
      activeTier = 'tier0101';
    } else if (isInActiveSalesConversation) {
      // Ensure clinics in active sales conversations render as activeSalesBase plan
      activeTier = 'tier0103';
    } else if (isHonoredBaseClinic) {
      // Ensure Honored Base clinics render as hononored plan
      activeTier = 'tier0102';
    }
  }

  const entitlements = {
    rpmReport: false,
    summaryDashboard: false,
    tideDashboard: false,
    patientTags: false,
  };

  const display = {
    planName: true,
    patientCount: true,
    patientLimit: false,
    workspacePlan: false,
    workspaceLimitDescription: false,
    workspaceLimitFeedback: false,
    workspaceLimitResolutionLink: false,
  };

  const details = {
    patientLimitEnforced: false,
    display,
    entitlements,
  };

  const tierSpecificOverrides = {
    tier0100: {
      planName: 'base',
      patientLimitEnforced: true,
      display: { ...display, patientLimit: true, workspacePlan: true },
    },
    tier0101: {
      planName: 'internationalBase',
      display: { ...display, planName: false },
      entitlements: { ...entitlements },
    },
    tier0102: {
      planName: 'honoredBase',
      display: { ...display, workspacePlan: true },
    },
    tier0103: {
      planName: 'activeSalesBase',
      display: { ...display, workspacePlan: true },
    },
    tier0200: {
      planName: 'essential',
    },
    tier0201: {
      planName: 'essential',
      entitlements: { ...entitlements, patientTags: true, summaryDashboard: true },
    },
    tier0202: {
      planName: 'professional',
      entitlements: { ...entitlements, patientTags: true, summaryDashboard: true },
    },
    tier0300: {
      planName: 'professional',
      entitlements: { ...entitlements, patientTags: true, summaryDashboard: true },
    },
    tier0301: {
      planName: 'professional',
      entitlements: { rpmReport: true, patientTags: true, summaryDashboard: true, tideDashboard: true },
    },
    tier0302: {
      planName: 'professional',
      entitlements: { ...entitlements, rpmReport: true, patientTags: true, summaryDashboard: true },
    },
    tier0303: {
      planName: 'professional',
      entitlements: { rpmReport: true, patientTags: true, summaryDashboard: true, tideDashboard: true },
    },
    tier0400: {
      planName: 'enterprise',
      entitlements: { rpmReport: true, patientTags: true, summaryDashboard: true, tideDashboard: true },
    },
  };

  return {
    ...details,
    ...tierSpecificOverrides[activeTier],
  }
};


export const clinicValuesFromClinic = clinic => ({
  name: get(clinic, 'name', ''),
  address: get(clinic, 'address', ''),
  city: get(clinic, 'city', ''),
  state: get(clinic, 'state', ''),
  postalCode: get(clinic, 'postalCode', ''),
  country: get(clinic, 'country', 'US'),
  clinicType: get(clinic, 'clinicType', ''),
  preferredBgUnits: get(clinic, 'preferredBgUnits', ''),
  website: get(clinic, 'website', ''),
  ...(get(clinic,'timezone')) && { timezone: clinic.timezone }
});




export const appointementSchema = yup.object().shape({
	userId : yup.string().required(),
	date: yup.date()
	 .transform((value, originalValue) => {
      value = moment(originalValue, 'YYYY-MM-DD', true);
      return value.isValid() ? value.toDate() : new Date('');
    })
	 .min(moment())
	 .required(),
	hour: yup.date()
	.transform((value, originalValue) => {
      value = moment(originalValue, 'HH:mm', true);
      return value.isValid() ? value.toDate() : new Date('');
    })
	.required(),
});

export const constantsSchema = yup.object().shape({
	weight : yup.number().positive().required(t('Weight is a required field, must be a positive number')),
	time: yup.date()
	 .transform((value, originalValue) => {
      value = moment(originalValue, 'YYYY-MM-DD', true);
      return value.isValid() ? value.toDate() : new Date('');
    }).required(t('Date is a required field')),
	height: yup.number().positive().integer().required(t('Height is a required field, must be a integer positive number')),
});

export const clinicPatientTagSchema = yup.object().shape({
  name: yup.string()
    .max(20, t('Tag name max length is ${max} characters'))
    .matches(/^[\p{L}\p{N}_+><-]{1}[\p{L}\p{N}\s_+><-]*$/u, t('Allowed special characters: - _ + > <'))
});

export const reportSchema = yup.object().shape({
	clinicianId: yup.string(),
	blobId: yup.object(),
	professionalName: yup.string().required(t('Professional name is a required Field')),
	subType: yup
    .string()
    .oneOf(map(roles, 'value'))
    .required(t('Please select a report type')),
	time: yup.date()
	 .transform((value, originalValue) => {
      value = moment(originalValue, 'YYYY-MM-DD', true);
      return value.isValid() ? moment(value.toDate()).toISOString() : new Date('');
    }).required(t('Date is a required field'))
});

export const clinicUIDetails = (clinic = {}) => {
  const { display, ...tierDetails } = clinicTierDetails(clinic);
  const { patientCount, patientCountSettings } = clinic;
  const patientCountHardLimit = patientCountSettings?.hardLimit?.patientCount;


  const details = {
    ...tierDetails,
    ui: {
      display,
    }
  }

  return details;
};

export const clinicSchema = yup.object().shape({
  name: yup.string().required(t('Please enter an organization name')),
  address: yup.string().required(t('Please enter an address')),
  city: yup.string().required(t('Please enter a city')),
  country: yup
    .string()
    .oneOf(keys(countries.getAlpha2Codes()))
    .required(t('Please enter a country')),
  state: yup
    .string()
    .required(t('Please enter a state'))
    .when('country', (country, schema) => !includes(keys(states), country)
      ? schema.required(t('Please enter a state'))
      : schema.oneOf(keys(states[country]), t('Please enter a valid state'))
    ),
  postalCode: yup
    .string()
    .required(t('Please enter a zip/postal code'))
    .when('country', (country, schema) => !includes(keys(postalCodes), country)
      ? schema.required(t('Please enter a zip/postal code'))
      : schema.matches(postalCodes[country], t('Please enter a valid zip/postal code'))
    ),
  clinicType: yup
    .string()
    .oneOf(map(clinicTypes, 'value'))
    .required(t('Please select a clinic type')),
  preferredBgUnits: yup
    .string()
    .oneOf(map(preferredBgUnits, 'value'))
    .required(t('Please select your preferred BG units')),
  website: yup
    .string()
    .url(({ value }) => /^https?:\/\//.test(value)
      ? t('Please enter a valid website address')
      : t('Please enter a valid website address with https:// at the beginning')
    ),
  timezone: yup.string(),
});


/**
 * yup schema for patient form
 * @function patientSchema
 * @param {Object} [config]
 * @param {Array} [config.existingMRNs] - array of existing MRNs to check against
 * @param {Object} [config.mrnSettings]
 * @param {boolean} [config.mrnSettings.required] - whether or not the MRN field is required
 * @returns {Object} yup schema
 *
 * @example
 * import { patientSchema } from 'core/clinicUtils';
 *
 * const schema = patientSchema({ mrnSettings:{ required: true } });
 *
 */
export const patientSchema = config => {
  let mrnSchema = yup
    .string()
    .matches(/^$|^[A-Z0-9]{4,25}$/, () => (
      <div>
        {t('Patient\'s MRN is invalid. MRN must meet the following criteria:')}
        <ul>
          <li>{t('All upper case letters or numbers')}</li>
          <li>{t('Minimum length: 4 characters')}</li>
          <li>{t('Maximum length: 25 characters')}</li>
          <li>{t('No spaces')}</li>
        </ul>
      </div>
    ))
    .notOneOf(config?.existingMRNs || [], t('This MRN is already in use. Please enter a valid MRN.'));

  if (config?.mrnSettings?.required) {
    mrnSchema = mrnSchema.required(t('Patient\'s MRN is required'));
  }

  return yup.object().shape({
    fullName: yup.string().required(t('Please enter the patient\'s full name')),
    birthDate: yup.date()
      .transform((value, originalValue) => {
        value = moment(originalValue, dateFormat, true);
        return value.isValid() ? value.toDate() : new Date('');
      })
      .min(moment().subtract(130, 'years').format(dateFormat), t('Please enter a date within the last 130 years'))
      .max(moment().subtract(1, 'day').format(dateFormat), t('Please enter a date prior to today'))
      .required(t('Patient\'s birthday is required')),
    mrn: yup.string(),
	diagnosisType: yup.string(),
    email: yup.string().email(t('Please enter a valid email address')),
    connectDexcom: yup.boolean(),
    dataSources: yup.array().of(
      yup.object().shape({
        providerName: yup.string(),
        state: yup.string().oneOf(['pending', 'pendingReconnect', 'connected', 'error', 'disconnected']),
      }),
    ),
    tags: yup.array().of(
      yup.string()
    ),
  })
};

export const tideDashboardConfigSchema = yup.object().shape({
  period: yup
    .string()
    .oneOf(map(summaryPeriodOptions, 'value'))
    .required(t('Please select a duration period')),
  lastUpload: yup
    .number()
    .oneOf(map(lastUploadDateFilterOptions, 'value'))
    .required(t('Please select a last upload date option')),
  tags: yup.array().of(yup.string())
    .min(1, t('Please select at least one tag')),
});